import { DIMR_LISTING_TYPE_PART } from '../constants/dimr';
import { DUMMY_LISTING_TITLE, LISTING_CONDITION } from '../constants/listing';
import { LOCALE, getCurrentLocale } from './locale';
import { capitalizeWord, camelize } from './string';
import { EXTENDED_DATA_SCHEMA_TYPES } from './types';

/**
 * Pick extended data fields from given data. Picking is based on extended data configuration
 * for the listing and target scopa and transaction process alias.
 *
 * With 'clearExtraCustomFields' parameter can be used to clear unused values for sdk.listings.update call.
 * It returns null for those fields that are managed by configuration, but don't match target process alias.
 *
 * @param {Object} data values to look through against listingConfig.js and util/configHelpers.js
 * @param {String} targetScope Check that the scope of extended data the config matches
 * @param {String} targetListingType Check that the extended data is relevant for this listing type.
 * @param {Object} listingFieldConfigs an extended data configurtions for listing fields.
 * @param {boolean} clearExtraCustomFields If true, returns also custom extended data fields with null values
 * @returns Array of picked extended data fields
 */
export const pickListingFieldsData = (
  data,
  targetScope,
  targetListingType,
  listingFieldConfigs,
  clearExtraCustomFields = false
) => {
  return listingFieldConfigs.reduce((fields, field) => {
    const { key, includeForListingTypes, scope = 'public', schemaType } = field || {};

    const isKnownSchemaType = EXTENDED_DATA_SCHEMA_TYPES.includes(schemaType);
    const isTargetScope = scope === targetScope;
    const isTargetListingType =
      includeForListingTypes == null || includeForListingTypes.includes(targetListingType);

    if (isKnownSchemaType && isTargetScope && isTargetListingType) {
      const fieldValue = data[key] || null;
      return { ...fields, [key]: fieldValue };
    } else if (
      isKnownSchemaType &&
      isTargetScope &&
      !isTargetListingType &&
      clearExtraCustomFields
    ) {
      return { ...fields, [key]: null };
    }
    return fields;
  }, {});
};

export const resolveDraftListingTitle = (intl, title) =>
  title === DUMMY_LISTING_TITLE || title === '- - -'
    ? intl.formatMessage({ id: 'General.noTitleSet' })
    : title;

export const createListingTitle = (listingPublicData, listingFieldsConfig, intl) => {
  const {
    articleNumber,
    dimrName,
    dimrGenericNameShort,
    // category,
    // subcategory,
    // itemType,
  } = listingPublicData;

  if (!articleNumber) {
    return intl.formatMessage({ id: 'General.noTitleSet' });
    // return `${toCapitalizedWords(camelCase(category)) || '-'} ${toCapitalizedWords(
    //   camelCase(subcategory)
    // ) || '-'} ${toCapitalizedWords(camelCase(itemType)) || '-'}`;
  }

  if (dimrName?.includes(`${articleNumber}`) && dimrName.length > `${articleNumber}`.length)
    return dimrName;

  return `${articleNumber || '-'} ${dimrGenericNameShort || '-'}`;
};

const DIMR_FIELDS_TO_ORDER = [
  'type',
  'name',
  'genericNameShort',
  'articleDescription',
  'model',
  'productName',
  'productNameShort',
  'productDescription',
];

export const DIMR_FIELD_SORT_ORDER = DIMR_FIELDS_TO_ORDER.reduce(
  (sortOrder, fieldKey, index) => ({
    ...sortOrder,
    [fieldKey]: DIMR_FIELDS_TO_ORDER.length - index,
  }),
  {}
);

export const dimrPrefix = key => `dimr${capitalizeWord(key)}`;

export const getDIMRUnitFromUnitString = unitString => {
  if (!unitString) return '-';

  // example: unece.unit.VLT
  const splitString = unitString.split('.');

  return splitString[2] || splitString[0] || '-';
};

export const getDIMRValueWithLang = (value, interfaceLang) => {
  if (!value) return '-';

  const valueAsEntries = Object.entries(value);

  if (!valueAsEntries.length) return '-';

  valueAsEntries.forEach(entry => {
    const [lang, value] = entry;

    if (lang.includes(interfaceLang)) return value;
  });

  // Take first value as fallback
  return valueAsEntries[0][1];
};

// Some fields should be translated manually
const resolveDIMRManualField = (intl, field) => {
  const { key, value } = field;

  switch (key) {
    case 'type':
      return value === DIMR_LISTING_TYPE_PART ? intl.formatMessage({ id: 'General.part' }) : value;

    default:
      break;
  }

  return null;
};

export const getDIMRFieldsWithResolvedValues = (intl, dimrFields, interfaceLang) =>
  dimrFields.map(field => {
    const { key, value } = field;

    const keyFinal = dimrPrefix(key);

    const isPlainValue = typeof value === 'string';
    const isUnitValue = typeof value?.unit === 'string';

    const manualValue = resolveDIMRManualField(intl, field);

    const isManualValuePlain = typeof manualValue === 'string';

    const inputValue = isManualValuePlain
      ? manualValue
      : isPlainValue
      ? value
      : isUnitValue
      ? value.value
      : getDIMRValueWithLang(value, interfaceLang);

    return { key: keyFinal, value: inputValue };
  });

export const getConditionForBulk = (condition, intl) => {
  const isBrazilLocale = getCurrentLocale() === LOCALE.BRAZIL;

  switch (condition) {
    case 'A':
    case LISTING_CONDITION.GRADE_A:
      return isBrazilLocale
        ? intl.formatMessage({ id: 'General.gradeALongBrazil' })
        : intl.formatMessage({ id: 'General.gradeALong' });

    case 'B':
    case LISTING_CONDITION.GRADE_B:
      return intl.formatMessage({ id: 'General.gradeBLong' });

    case 'C':
    case LISTING_CONDITION.GRADE_C:
      return intl.formatMessage({ id: 'General.gradeCLong' });

    default:
      return intl.formatMessage({ id: 'General.noGrading' });
  }
};

export const LISTING_CLOSE_REASON = {
  TX_FULFILLED: 'tx-fulfilled',
  NOT_AVAILABLE_OTHER: 'not-available-other',
};

export const LISTING_CLOSE_OPTIONS = Object.values(LISTING_CLOSE_REASON).map(val => ({
  key: val,
  label: `ManageListingsPage.closeListing${capitalizeWord(camelize(val))}`,
}));
